<template>
  <b-row class="mx-0 w-100">
    <b-overlay
      class="row"
      :show="showLoading"
      rounded="sm"
      spinner-variant="primary"
      variant="transparent"
      spinner-type="grow"
      spinner-small
      blur="1rem"
    >
      <b-col md="4" sm="12" class="order-1 order-md-0">
        <b-card>
          <b-form-group
            :label="$t('Pages.Assistant.Label.select_ch')"
            label-for="h-channel"
            label-cols-md="4"
          >
            <v-select
              id="h-channel"
              v-model="selectedChannel"
              :dir="$i18n.locale == 'fa' ? 'rtl' : 'ltr'"
              label="nickname"
              :options="channels"
            />
          </b-form-group>
          <b-form-group
            :label="$t('Pages.Assistant.Label.count_vid')"
            label-for="h-channel"
            label-cols-md="5"
            class="d-flex justify-content-center align-items-center"
          >
            <div class="text-left">
              <span class="px-1" v-if="selectedChannel"
                ><b-badge style="margin-left: 3px" pill variant="primary">{{
                  selectedChannel.videos ? selectedChannel.videos : 0
                }}</b-badge
                >{{ $t("Pages.Assistant.Title.Videos") }}</span
              >
            </div>
          </b-form-group>

          <b-form-group
            :label="$t('Pages.Assistant.Label.count_vid_analysis')"
            label-for="h-channel"
            label-cols-md="4"
          >
            <v-select
              id="h-channel"
              v-model="count_video"
              :dir="$i18n.locale == 'fa' ? 'rtl' : 'ltr'"
              :options="items"
              :disabled="!selectedChannel"
            />
          </b-form-group>

          <b-form-group
            :label="$t('Pages.Assistant.Label.point_used')"
            label-for="h-channel"
            label-cols-md="4"
            class="d-flex justify-content-center align-items-center"
          >
            <div class="text-left">
              <span class="px-1"
                ><b-badge style="margin-left: 3px" pill variant="primary">{{
                  count_video != null
                    ? base_coin + Math.ceil(count_video.id / 50)
                    : 0
                }}</b-badge
                >{{ $t("Pages.Assistant.Title.Coin") }}</span
              >
            </div>
          </b-form-group>

          <!-- <b-form-group :label="$t('Number Of Competing Channels To Analyze') + ': ' + count_competitor_channel" label-for="h-channel"
                    label-cols-md="4" class="d-flex justify-content-center align-items-center">
                    <div class="d-flex justify-content-center align-items-center w-100">
                        <span class="px-1">{{max_competitor}}</span>
                        <vue-slider v-model="count_competitor_channel" width="100%"
                            :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :min="min_competitor" :max="max_competitor"
                            :disabled="!selectedChannel" />
                        <span class="px-1">{{min_competitor}}</span>
                    </div>
                </b-form-group> -->
          <b-col cols="12"> </b-col>
        </b-card>

        <b-card>
          <!-- <b-card-header class="text-center d-flex justify-content-center align-items-center pt-0">
                    <b-card-title class="text-center">{{$t("Please Enter Your Competitor's Channel Link")}}</b-card-title>
                </b-card-header>
                <div v-for="(channel,index) in count_competitor_channel" :key="index"> 
                    <b-form-group  :label="$t('Channel Link')" label-for="h-channel" label-cols-md="2" :disabled="!selectedChannel"
                    class="d-flex justify-content-center align-items-center my-auto py-1">
                    <b-form-input v-model="competitor_channel[index]" :disabled="!selectedChannel" />
                    </b-form-group>
                </div> -->
          <b-spinner
            class="mx-auto"
            v-if="waiting"
            type="grow"
            label="Loading..."
          />
          <b-button
            variant="primary"
            class="w-100 mt-1"
            :disabled="!selectedChannel"
            @click="startAnalysis"
            >{{ $t("Pages.Assistant.Button.start_analys") }}</b-button
          >
        </b-card>
      </b-col>
      <b-col md="8" sm="12" class="order-0 order-md-1">
        <b-card>
          <p>{{ $t("Pages.Assistant.Title.intro_txt") }}</p>
          <p>
            {{
              $t("Pages.Assistant.Title.continu_intro_txt")
            }}
          </p>
          <b-row>
            <b-col cols="7">
              <ul>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_1") }}
                </li>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_2") }}
                </li>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_3") }}
                </li>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_4") }}
                </li>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_5") }}
                </li>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_6") }}
                </li>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_7") }}
                </li>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_8") }}
                </li>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_9") }}
                </li>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_10") }}
                </li>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_11") }}
                </li>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_12") }}
                </li>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_13") }}
                </li>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_14") }}
                </li>
                <li>
                  {{ $t("Pages.Assistant.Title.intro_txt_15") }}
                </li>
              </ul>
            </b-col>
            <b-col cols="5"
              ><b-img
                :src="
                  require('@/assets/images/assistant/review-request-woman.svg')
                "
                class="w-75"
                alt="decore-1"
              ></b-img
            ></b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-overlay>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VueSlider from "vue-slider-component";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BModal,
    BCardBody,
    BOverlay,
    BCardTitle,
    BPaginationNav,
    BCardSubTitle,
    vSelect,
    VueSlider,
    BSpinner,
  },
  data() {
    return {
      channels: [],
      selectedChannel: null,
      items: [
        {
          id: 50,
          label: this.$t("Pages.Assistant.Title.{count} last_vid", {
            count: 50,
          }),
        },
        {
          id: 100,
          label: this.$t("Pages.Assistant.Title.{count} last_vid", {
            count: 100,
          }),
        },
        {
          id: 150,
          label: this.$t("Pages.Assistant.Title.{count} last_vid", {
            count: 150,
          }),
        },
        {
          id: 200,
          label: this.$t("Pages.Assistant.Title.{count} last_vid", {
            count: 200,
          }),
        },
      ],
      count_video: null,
      count_competitor_channel: 1,
      min_competitor: 1,
      max_competitor: 2,
      competitor_channel: [""],
      showLoading: false,
      membership: {},
      base_coin: 25,
      count_coin: 0,
      waiting: false,
    };
  },
  watch: {
    count_competitor_channel(val, pres) {
      if (val > pres) {
        this.competitor_channel = [...this.competitor_channel, ""];
      } else {
        this.competitor_channel.pop();
      }
    },
  },
  created() {
    this.getCountCoin();
    this.getBaseCoin();
    this.getChannelList();
    this.getUsermembership();
  },
  methods: {
    getCountCoin() {
      this.$http.get(`/api/dashboard/getCountCoin`).then((response) => {
        this.count_coin = response.data.count_coin;
      });
    },
    getUsermembership() {
      this.$http.get("/api/dashboard/getUserPlan").then((response) => {
        this.membership = response.data;
      });
    },
    getBaseCoin() {
      this.$http.get("/api/tools/assistant/base-coin").then((response) => {
        if (response.data) {
          this.base_coin = response.data.count;
        }
      });
    },
    getChannelList() {
      this.$http
        .get("/api/tools/assistant/dropdown/list-channel")
        .then((response) => {
          this.channels = response.data;
        });
    },
    startAnalysis() {
      if (!this.waiting) {
        this.waiting = true;
        this.$http.get("/api/user/setting/check-verify").then((response) => {
          if (response.data && response.data.status) {
            if (this.selectedChannel.id != "") {
              if (this.count_video.id > 0) {
                if (
                  this.count_coin >=
                  this.base_coin + Math.ceil(this.count_video.id / 50)
                ) {
                  this.$http
                    .post(
                      "/api/tools/assistant/start-analysis",
                      {
                        channel_id: this.selectedChannel.id,
                        count_video: this.count_video.id,
                        quota:
                          this.base_coin + Math.ceil(this.count_video.id / 50),
                        //  competitor_channel: this.competitor_channel
                      },
                      { timeout: 200000000 }
                    )
                    .then((response) => {
                      if (response.data.status) {
                        this.$swal({
                          title: this.$t(
                            "Pages.Assistant.Title.success_app_reg"
                          ),
                          text: this.$t(
                            "Pages.Assistant.Title.success_app_reg_txt"
                          ),
                          imageUrl: require("@/assets/images/assistant/review-request-man.svg"),
                          imageWidth: 400,
                          imageHeight: 200,
                          imageAlt: "Custom image",
                          customClass: {
                            confirmButton: "btn btn-primary",
                          },
                          buttonsStyling: false,
                          confirmButtonText: this.$t(
                            "Pages.Assistant.Button.success_app_reg_btn"
                          ),
                        }).then((result) => {
                          this.$router.push({ name: "assistant-reviews" });
                        });
                        this.$root.$emit("updateCoin", true);
                      }
                    })
                    .catch((error) => {
                      this.makeToast(
                        this.$t("Base.Alert.Error"),
                        this.$t("Base.Alert.error_server"),
                        "danger"
                      );
                    });
                  this.waiting = false;
                  // if (this.competitor_channel.length > 0 && this.competitor_channel[0] != "" && this.competitor_channel[1] != "") {
                  //     if (this.competitor_channel[0] != this.competitor_channel[1]) {

                  //     } else {
                  //         this.makeToast(
                  //             this.$t("Alert.Error"),
                  //             this.$t("Please Use Different Channels As Competitors"),
                  //             "danger"
                  //         );
                  //     }
                  // } else {
                  //     this.makeToast(
                  //         this.$t("Alert.Error"),
                  //         this.$t("Please Add A Channel As A Competitor"),
                  //         "danger"
                  //     );
                  // }
                } else {
                  this.$swal({
                    title: this.$t("Base.Alert.Error"),
                    text: this.$t("Base.Alert.limit_add_ch"),
                    icon: "warning",
                    confirmButtonText: this.$t(
                      "Navbar.Button.upgrade_account"
                    ),
                    customClass: {
                      confirmButton: "btn btn-primary",
                      cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                  }).then((result) => {
                    if (result.value) {
                      this.$router.push("/pricing").catch(() => {});
                    }
                  });
                }
                this.waiting = false;
              } else {
                this.makeToast(
                  this.$t("Base.Alert.Error"),
                  this.$t(
                    "Base.Alert.must_one_vid"
                  ),
                  "danger"
                );
              }
            }
          } else {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t(
                "Base.Alert.verify_email_phone"
              ),
              "danger"
            );
            this.waiting = false;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-slider.scss";

[dir] .vs--disabled .vs__dropdown-toggle,
[dir] .vs--disabled .vs__clear,
[dir] .vs--disabled .vs__search,
[dir] .vs--disabled .vs__selected,
[dir] .vs--disabled .vs__open-indicator {
  background-color: inherit !important;
}
</style>
